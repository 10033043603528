.root {
  max-width: 1200px;
  padding: 64px;
  padding-right: 48px;
  background: #FFFFFF;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(0, 0, 0, 0.02);
  border-radius: 24px;
  overflow-y: hidden;
  position: relative;
  margin: 20px auto;
  @media (max-width: 1500px) {
    max-width: 100%;
    margin: 30px;
    padding: 48px 32px;
    padding-right: 16px;
  }
  @media (max-width: 991px) {
    margin: 0;
    margin-top: 8px;
    padding: 48px 8px 0 16px;
    border-radius: 24px 24px 0 0;

    height: 100%;
  }
}

.inner {
  overflow-y: auto;
  max-height: 100%;

}

.img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 24px;
  margin-bottom: 40px;
  @media (max-width: 1500px) {
    height: 400px;
  }
  @media (max-width: 991px) {
    height: 300px;
    margin-bottom: 16px;
  }
  @media (max-width: 768px) {
    height: 152px;
  }
}

.title {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #222222;
  margin-bottom: 24px;
  @media (max-width: 991px) {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.text {
  font-size: 18px;
  line-height: 150%;
  color: #595959;
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
  @media (max-width: 991px) {
    font-size: 15px;
    line-height: 150%;
  }
}

.inner::-webkit-scrollbar {
  width: 16px;               /* ширина scrollbar */
  border-radius: 4px;
  @media (max-width: 991px) {
    width: 13px;
  }
}
.inner::-webkit-scrollbar-track {
  background:  #fff;   /* цвет дорожки */
}
.inner::-webkit-scrollbar-thumb {
  background: #D9D9D9;    /* цвет плашки */
  border-left: 10px solid #fff;
  @media (max-width: 991px) {
    border-left: 9px solid #fff;
  }
}

.xMark {
  position: absolute;
  top: 25px;
  cursor: pointer;
  right: 25px;
  @media (max-width: 1500px) {
    height: 14px;
    width: 14px;
    right: 17px;
    top: 17px;
  }
}