.title {
  font-size: 18px;
  line-height: 20px;
  padding-top: 24px;
  position: relative;
  margin: 0 40px 0 0;
  font-weight: 700;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.title:after {
  position: absolute;
  background-color: #13ad37;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

@media (max-width: 990px) {
  .title {
    padding: 0 0 0 24px;
    margin: 0;
    writing-mode: unset;
    transform: none;
    margin-bottom: 20px;
  }

  .title:after {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 16px;
    line-height: 18px;
  }

  .title__wrapper {
    align-self: flex-start;
  }
}
