.root {
  background: #fafafa;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  transform: translateY(80px);
  opacity: 0;
  min-width: 285px !important;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.root_active,
.root_active,
.root_active,
.root_active,
.root_active,
.root_active {
  transform: translate(0);
  opacity: 1;
}

.header {
  position: relative;
  z-index: 2;
  margin: 40px 0 62px;
  padding: 0 40px 0 0;
  display: flex;
  align-items: center;
}

.line {
  background: #13ad37;
  width: calc(100% - 63px);
  height: 2px;
  transition: transform 0.3s ease-in-out;
}

.number {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #13ad37;
  margin-left: auto;
  transition: transform 0.3s ease-in-out;
}

.body {
  position: relative;
  z-index: 2;
  padding: 0 32px 40px;
}

.icon {
  display: block;
  max-height: 60px;
  margin-bottom: 24px;
  opacity: 0;
  transform: translateY(100px);
  transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
}

.icon_active {
  opacity: 1;
  transform: translate(0);
}

.name {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #171717;
  position: relative;
  overflow: hidden;
  opacity: 0;
  transform: translateY(200px);
  transition: background 0.3s ease-in-out, transform 0.5s ease-in-out,
    opacity 0.2s ease-in-out;
}

.name_active {
  opacity: 1;
  transform: translate(0);
  transition-delay: 0s;
}

.background {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: 0;
  background: #f0f0f0;
}

.root:hover .number {
  transform: scale(1.4);
}

.root:hover .line {
  transform: translateX(-10px);
}

.root:hover .icon {
  transform: translateY(-10px);
}

.root:hover .name {
  box-decoration-break: clone;
  background-repeat: no-repeat;
  background-image: linear-gradient(180deg, #13ad37, #13ad37);
  background-position: 0 120%;
  background-size: 100% 12px;
}

@media (min-width: 1600px) {
  .name {
    font-size: 20px;
    line-height: 24px;
  }
  .number {
    font-size: 28px;
    line-height: 34px;
  }
  .header {
    margin: 40px 0 110px;
  }
}

@media (max-width: 1365px) {
  .name {
    font-size: 16px;
    line-height: 19px;
  }
  .number {
    font-size: 20px;
    line-height: 24px;
  }
  .header {
    margin: 40px 0;
  }
}

@media (max-width: 600px) {
  .name {
    font-size: 18px;
    line-height: 22px;
  }
  .number {
    font-size: 24px;
    line-height: 29px;
  }
  .header {
    margin: 40px 0 78px;
  }

 .root {
  animation: none;
 }

 .root_active,
.root_active,
.root_active,
.root_active,
.root_active,
.root_active,
.name,
.icon {
  transform: translate(0);
  transition: none;
  opacity: 1;
}
}
