.root {
  background: #fff;
  border-radius: 24px;
  padding: 24px !important;
  box-sizing: border-box;
  min-width: 395px;
  width: 100%;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 16px;
}
.root::-webkit-scrollbar {
  display: none;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #1f222d;
  margin: 0 0 4px;
}

.address {
  font-size: 14px;
  line-height: 22px;
  color: #8e9aa9;
  margin: 0;
}

.persons {
  margin-top: 16px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(161px, 182px));
  gap: 24px 64px;
}

.position {
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  color: #1f222d;
  margin: 0 0 6px;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.item {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 2px;

  color: #8e9aa9;
}

.item a {
  text-decoration: none;
  color: inherit;
}

.item:before {
  content: '';
  display: block;
  margin-right: 8px;
  width: 8px;
  height: 8px;
  background: #13ad37;
  border-radius: 50%;
  flex: none;
}

.button {
  background: #fff;
  border: none;
  padding: 10px;
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
  outline: none;
}

@media (max-width: 500px) {
  .root {
    overflow: auto;
  }
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 414px) {
  .root {
    min-width: 284px;
    max-width: 284px;
  }
}
