.modal {
  min-width: 321px;
  width: 100%;
  padding: 24px;
  background: #fff;
  border-radius: 24px;
  box-sizing: border-box;
}

.modal__title {
  font-size: 14px;
  line-height: 17px;
  color: #8e9aa9;
  margin: 0 0 4px;
  font-weight: 400;
}

.modal__text {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
  margin: 0;
}

.button {
  background: transparent;
  border: none;
  padding: 0;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
  outline: none;
}

.button img {
  width: 12px;
}

@media (max-width: 330px) {
  .modal {
    min-width: 300px;
  }
}
