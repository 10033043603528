.root {
  margin-top: 80px;
  opacity: 0;
  transform: translateY(100px);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.root_active {
  opacity: 1;
  transform: translateY(0);
}

.block {
  display: flex;
  margin-right: 0;
  margin-left: calc((100% - 1268px) / 2);
  max-width: calc(1268px + (100% - 1268px) / 2);
}
.swiperModal {
  width: 65vw;
}

.imgModal {
  width: 65vw;
  user-select: none;
}

.content {
  background-color: #fafafa;
  border-radius: 24px 0 0 24px;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.next {
  top: 50%;
  bottom: 50%;
  right: 10%;
}
.prev {
  left: 10%;
  top: 50%;
  bottom: 50%;
}


.about {
  padding: 78px 64px;
}

.title {
  margin: 0 0 24px;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  max-width: 456px;
}



.title:first-line {
  background: linear-gradient(transparent 81%, #13AD37 19%);
  background-position-y: 34px;
}

@media (max-width: 768px) {
  .title:first-line {
    background-position-y: 25px;
  }
}

.description {
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 48px;
  max-width: 456px;
  color: #171717bf;
}

.button {
  padding: 11px 35px;
  margin-right: auto;
  align-items: center;
}

.button:before {
  background: linear-gradient(275.36deg, #13ad37 11.87%, #5dad13 86.48%);
}

.button:after {
  background: #119d32;
}

.img,
.slider {
  width: 665px;
  height: 545px;
  object-fit: cover;
  object-position: bottom right;
}

.slider {
  position: relative;
}

.controls {
  position: absolute;
  bottom: 24px;
  left: -54px;
  z-index: 10;
}

.arrow {
  width: 40px;
  height: 40px;
  background: linear-gradient(275.36deg, #13ad37 11.87%, #5dad13 86.48%);
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background-color 0.3s ease-in-out;
}

.arrow__inner {
  margin-left: 15px;
}

.arrow:hover {
  transform: scale(1.2);
}

.arrow_left {
  transform: rotate(180deg);
  margin: auto;
}

.arrow_right {
  margin: auto;
}

.controls {
  display: flex;
  gap: 28px;
}

@media (max-width: 1365px) {
  .img,
  .slider {
    width: 621px;
    height: 542px;
  }

  .about {
    padding: 56px 64px;
  }
}

@media (max-width: 1279px) {
  .block {
    margin: 0 auto;
    max-width: 911px;
  }

  .content {
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    padding: 56px 64px;
    width: calc(100% - 60px);
    box-sizing: border-box;
  }

  .about {
    padding: 0;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }

  .title {
    max-width: unset;
  }

  .description {
    max-width: unset;
  }

  .button {
    align-self: center;
    margin: 0;
  }

  .img {
    width: 100%;
    height: 100%;
  }

  .slider {
    width: 100%;
  }

  .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
  }

  .dot {
    border: none;
    width: 8px;
    height: 8px;
    background: #d9d9d9;
    border-radius: 50%;
    margin: 0 6px;
    padding: 4px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .dot:focus {
    outline: none;
  }

  .dot_active {
    background: #13ad37;
  }
}

@media (max-width: 1365px) {
  .block {
    margin-left: calc((100% - 1183px) / 2);
    max-width: calc(1183px + (100% - 1183px) / 2);
  }
}

@media (max-width: 1279px) {
  .block {
    margin-left: calc((100% - 911px) / 2);
    max-width: 911px;
  }
}

@media (max-width: 990px) {
  .block {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  .content {
    width: 100%;
  }

  .description {
    margin-bottom: 24px;
  }

  .slider {
    height: auto;
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media (max-width: 767px) {
  .content {
    padding: 0;
    background-color: transparent;
  }

  .root {
    margin-top: 60px;
  }

  .about {
    padding: 40px 16px;
    margin: 0;
    background-color: #fafafa;
    border-radius: 24px 24px 0 0;
  }

  .title {
    font-size: 24px;
    line-height: 28px;
  }

  .button {
    font-size: 16px;
  }

  .dots {
    margin-top: 16px;
  }
}

@media (max-width: 500px) {
  .block {
    align-items: flex-start;
  }
}
@media (max-width: 350px) {
  .button {
    font-size: 14px;
  }
}

@media (min-width: 1600px) {
  .img,
  .slider {
    width: 791px !important;
    height: 600px;
  }

  .root {
    margin-top: 96px;
  }

  .about {
    padding: 108px 64px;
  }

  .title {
    font-size: 36px;
    line-height: 43px;
    max-width: unset;
  }

  .description {
    font-size: 18px;
    line-height: 27px;
    margin: 0 0 48px;
    max-width: unset;
  }

  .block {
    margin-left: calc((100% - 1512px) / 2);
    max-width: calc(1512px + (100% - 1512px) / 2);
  }
}

@media (min-width: 1920px) {
  .block {
    margin-left: calc((100% - 1814px) / 2);
    max-width: calc(1814px + (100% - 1814px) / 2);
  }

  .img,
  .slider {
    width: 1100px !important;
    max-width: 1100px !important;
    height: 800px;
  }

  .controls {
    left: -62px;
  }

  .arrow {
    width: 48px;
    height: 48px;
  }

  .about {
    padding: 221px 64px;
  }

  .title {
    max-width: 577px;
  }
}

@media (min-width: 2560px) {
  .block {
    margin-left: calc((100% - 2063px) / 2);
    max-width: calc(2063px + (100% - 2063px) / 2);
  }

  .content {
    max-width: 2243px;
  }

  .img,
  .slider {
    width: 1271px !important;
    height: 800px;
  }

  .about {
    padding: 235px 64px;
  }
}

@media (max-width: 390px) {
  .dot {
    margin: 0 5px;
  }
}

@media (max-width: 320px) {
  .dot {
    margin: 0 4px;
  }
}
