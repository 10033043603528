.button {
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.active {
  circle {
    fill: #13AD37;
  }
  rect {
    fill: #13AD37;
  }
}