.root {
  overflow: hidden;
}

.wrapper {
  display: flex;
}

@media (min-width: 1600px) {
  .root {
    margin-top: 96px;
  }
}

@media (min-width: 1920px) {
  .wrapper {
    max-width: 1752px;
  }
}

@media (min-width: 2560px) {
  .items {
    grid-template-columns: repeat(4, 486px);
  }
  .wrapper {
    max-width: 2063px;
  }
}








@media (max-width: 991px) {
  .wrapper {
    flex-direction: column;
  }
}
