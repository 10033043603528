.slide {
  display: flex;
  height: initial;
  position: relative;
  min-width: 280px;
  @media (max-width: 991px) {
    max-width: 280px;

  }
}

.slider {
  position: relative;
  padding: 20px;
  margin: 0 -20px;
}

@media (max-width: 991px) {
  .slider {
    padding-right: 20px;
    margin-right: 0;
  }
}



.root {
  top: -20px;
  position: relative;
  max-width: calc(100% - 60px);
  @media (max-width: 991px) {
    max-width: 100%;
  }
}

.next {
  top: 50%;
  bottom: 50%;
  right: -24px;
  @media (max-width: 991px) {
    display: none;
  }
}

.prev {
  top: 50%;
  left: -24px;
  bottom: 50%;
  @media (max-width: 991px) {
    display: none;
  }
}


.wrapper {
  overflow: visible;
  margin-top: 80px;
  margin-bottom: 60px;
  @media (max-width: 991px) {
    >div {
      padding-right: 0 !important;
    }
  }
  @media (max-width: 991px) {
    margin-top: 60px;
    margin-bottom: 40px;
  }
}

.inner {
  padding-right: 20px;
}

