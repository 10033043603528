.root {
  margin-top: 80px;
}

.wrapper {
  display: flex;
}

.items {
  display: grid;
  grid-template-columns: repeat(4, 287px);
  grid-template-rows: repeat(3, 196px);
  gap: 20px;
}

.card {
  background: #fafafa;
  border-radius: 24px;
  display: flex;
  overflow: hidden;
  transform: translateY(100px);
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.card_active_1,
.card_active_2,
.card_active_3,
.card_active_4,
.card_active_5,
.card_active_6,
.card_active_7,
.card_active_8,
.card_active_9,
.card_active_10,
.card_active_11,
.card_active_12 {
  transform: translate(0);
  opacity: 1;
}

.card_active_2 {
  transition-delay: 0.05s;
}

.card_active_3 {
  transition-delay: 0.1s;
}

.card_active_4 {
  transition-delay: 0.15s;
}

.card_active_5 {
  transition-delay: 0.2s;
}

.card_active_6 {
  transition-delay: 0.25s;
}

.card_active_7 {
  transition-delay: 0.3s;
}

.card_active_8 {
  transition-delay: 0.35s;
}

.card_active_9 {
  transition-delay: 0.4s;
}

.card_active_10 {
  transition-delay: 0.45s;
}

.card_active_11 {
  transition-delay: 0.5s;
}

.card_active_12 {
  transition-delay: 0.55s;
}

.image {
  width: 100%;
  object-fit: contain;
}

@media (min-width: 1600px) {
  .root {
    margin-top: 96px;
  }
  .items {
    grid-template-columns: repeat(4, 348px);
    grid-template-rows: repeat(3, 196px);
  }
}

@media (min-width: 1920px) {
  .wrapper {
    max-width: 1752px;
  }
  .items {
    grid-template-columns: repeat(4, 408px);
    grid-template-rows: repeat(3, 236px);
  }
}

@media (min-width: 2560px) {
  .items {
    grid-template-columns: repeat(4, 486px);
  }
  .wrapper {
    max-width: 2063px;
  }
}

@media (max-width: 1365px) {
  .items {
    grid-template-columns: repeat(4, 266px);
    grid-template-rows: repeat(3, 170px);
  }
}

@media (max-width: 1279px) and (min-width: 991px) {
  .items {
    grid-template-columns: repeat(3, 270px);
    grid-template-rows: repeat(4, 170px);
  }
}

@media (max-width: 990px) {
  .wrapper {
    flex-direction: column;
  }

  .items {
    grid-template-columns: repeat(3, calc(100% / 3 - 14px));
    grid-template-rows: repeat(4, 170px);
  }
}

@media (max-width: 767px) {
  .root {
    margin-top: 60px;
  }
}

@media (max-width: 700px) {
  .items {
    grid-template-rows: repeat(4, 130px);
  }
}

@media (max-width: 500px) {
  .wrapper {
    align-items: flex-start;
  }
  .items {
    grid-template-columns: repeat(3, 104px);
    grid-template-rows: repeat(4, 100px);
    gap: 8px;
    align-self: center;
  }
  .image {
    object-fit: cover;
  }
}

@media (max-width: 350px) {
  .items {
    grid-template-columns: repeat(3, 90px);
    grid-template-rows: repeat(4, 87px);
  }
}
