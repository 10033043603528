.root {
  background: #454545;
  border-radius: 24px;
  padding: 80px 0;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.hideTooltip #tooltip-map {
  display: none;
}

.wrapper {
  position: absolute;
  top: 0;
}

.shape {
  position: relative;
  z-index: 1;
}

.content {
  position: absolute;
  z-index: 2;
  top: 162px;
  max-width: 1268px;
  width: 100%;
  margin: 0 auto;
  height: calc(100% - 162px);

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.content::-webkit-scrollbar {
  display: none;
}

.map__rep {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 2;
  padding: 0 16px;
  display: none;
}

.map__rep path {
  transition: fill 0.3s ease-in-out;
}

.map__rep_active {
  opacity: 1;
  visibility: visible;
  z-index: 3;
  display: block;
}

.map__dist {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -450px;
  left: 30px;
  z-index: 2;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.map__dist_active {
  opacity: 1;
  visibility: visible;
  z-index: 3;
}

.toggle {
  position: absolute;
  z-index: 4;
  width: calc(100% - 40px);
  top: 242px;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 24px;
}

.button {
  padding: 11px 47px;
  font-weight: 700;
  border-radius: 50px !important;
  font-size: 14px;
  line-height: 18px;
  box-sizing: border-box;
  color: #13ad37 !important;
  background: transparent;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
    border 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.button:not(.button_active):before {
  background: transparent;

  box-sizing: border-box;
  border: 2px solid #13ad37;
}

.button:last-of-type {
  padding: 11px 35px;
}

.button_active {
  background: linear-gradient(275.36deg, #13ad37 11.87%, #5dad13 86.48%);
  color: #fff !important;
  border: transparent;
}

.button:not(.button_active):hover {
  color: #fff !important;
}

.button:not(.button_active):hover:after {
  background: #119d32;
}

.title {
  color: #fff;
  top: 242px;
  left: 0;
  position: absolute;
  display: inline;
  z-index: 4;
}

.mapContainer {
  position: absolute;
  background: transparent !important;
  left: -40px;
  top: 0;
  width: 1646px !important;
  height: 825px !important;
}

@media (max-width: 1279px) {
  .map__rep {
    padding: 0 16px;
  }
  .map__dist {
    left: 0;
    padding: 16px;
  }
}

@media (max-width: 1050px) {
  .content {
    overflow: auto;
  }
}
@media (max-width: 990px) {
  .title {
    top: 180px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 500px) {
  .title {
    top: 60px;
    left: 16px;
    transform: translateX(0);
  }
  .toggle {
    top: 118px;
    flex-direction: column;
  }
  .button {
    width: 100%;
  }
  .shape {
    height: 900px;
    visibility: hidden;
  }

  .map__dist {
    top: -650px;
  }

  .shape_dist {
    height: 750px;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .root {
    padding: 0;
  }
  .wrapper {
    max-width: 1752px;
  }
  .shape {
    width: 1215px;
    transform: scale(1.07);
  }
  .map__rep {
    transform: scale(1.276) translate(-37px, 55px);
  }
  .map__dist {
    transform: scale(1.4);
    top: -550px;
  }
}

@media (min-width: 2560px) {
  .root {
    padding: 0;
  }
  .wrapper {
    max-width: 2063px;
  }
  .shape {
    width: 1463px;
    transform: scale(1.13);
  }
  .map__rep {
    transform: scale(1.8) translate(140px, 65px);
  }
  .map__dist {
    transform: scale(1.65);
    top: -550px;
  }
}
