
.imgModal {
  height: 80vh;
  margin: 0 auto;
  user-select: none;
}

.next {
  top: 50%;
  bottom: 50%;
  right: 10%;
}
.prev {
  left: 10%;
  top: 50%;
  bottom: 50%;
}

.swiperModal {
  max-width: 65vw;
  width: auto;
}

.swiperSlide {
  display: flex;
  align-items: center;
}
