.button {
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.inactive svg{
  circle {
    fill: #F0F0F0;
  }
  rect {
    fill: #F0F0F0;
  }
}