.root {
  margin-top: 80px;
  opacity: 0;
  transform: translateX(100%);
  overflow: hidden;
}

.root_active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.content {
  background-color: #fafafa;
  border-radius: 24px 0 0 24px;
  padding: 27px 0 60px 60px;
  width: 100%;
  position: relative;
}

.wrapper {
  padding: 0;
}

.content::after {
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  right: -100%;
  position: absolute;
  background-color: #fafafa;
}

.item {
  position: relative;
  padding: 33px 0 29px;
  cursor: pointer;
  max-height: 102px;
  overflow: hidden;
  box-sizing: border-box;
  transition: max-height 0.6s ease-in-out;
}

.item_active:first-of-type {
  opacity: 0;
  animation: appearance 0.8s ease-in-out 1s forwards;
}

.item_active:nth-child(2) {
  opacity: 0;
  animation: appearance 0.8s ease-in-out 1.6s forwards;
}
.item_active:nth-child(3) {
  opacity: 0;
  animation: appearance 0.8s ease-in-out 2.2s forwards;
}
.item_active:nth-child(4) {
  opacity: 0;
  animation: appearance 0.8s ease-in-out 2.8s forwards;
}

.item_open {
  max-height: 240px;
}

.item:after {
  position: absolute;
  content: '';
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: #13ad37;
}

.item:last-child:after {
  content: none;
}

.question {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  padding-top: 7px;
}

.question__wrapper {
  display: flex;
  justify-content: space-between;
}

.answer {
  margin-top: 16px;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

.answer__inner {
  color: #171717;
  font-size: 18px;
  line-height: 24px;
}

.answer__link {
  color: #13ad37;
  text-decoration: underline;
  transition: text-decoration-color 0.3s ease-in-out;
}

.answer__link:hover {
  text-decoration-color: transparent;
}

.answer_open {
  opacity: 1;
}

.button {
  margin-left: 30px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #13ad37;
  font-size: 17px;
  color: #ffffff;
}

@media (max-width: 1366px) {
  .item {
    transition: max-height 0.33s ease-in-out;
  }

  .answer {
    transition: opacity 0.5s ease-in-out;
  }
}

@media (max-width: 1279px) {
  .question {
    font-size: 16px;
    line-height: 19px;
  }

  .answer__inner {
    font-size: 16px;
    line-height: 19px;
  }
}

@media (max-width: 990px) {
  .block {
    flex-direction: column;
    align-items: center;
  }

  .content {
    box-sizing: border-box;
    padding: 27px 60px 60px;
    border-radius: 24px;
  }

  .content::after {
    content: none;
  }
}
@media (max-width: 767px) {
  .block {
    flex-direction: column;
    padding: 0;
  }

  .content {
    padding: 0 16px 40px;
    box-sizing: border-box;
    border-radius: 24px;
  }

  .item_open {
    max-height: 279px;
  }

  .question {
    font-size: 16px;
    line-height: 19px;
  }

  .answer__inner {
    font-size: 16px;
    line-height: 19px;
  }

  .title_rotated {
    padding: 0 20px;
  }

  .root {
    margin-top: 60px;
  }
}

@media (max-width: 565px) {
  .item {
    max-height: 134px;
  }

  .item_open {
    max-height: 300px;
  }
}

@media (min-width: 1600px) {
  .root {
    margin-top: 96px;
  }

  .content {
    padding: 8px 0 8px 60px;
  }

  .item {
    padding: 64px 0 64px;
    max-height: 168px;
  }

  .item_open {
    max-height: 250px;
  }

  .question {
    font-size: 20px;
    line-height: 24px;
  }

  .answer__inner {
    font-size: 20px;
    line-height: 24px;
    max-width: calc(100% - 218px);
    display: block;
  }
}

@media (min-width: 2560px) {
  .block {
    margin-left: calc((100% - 2063px) / 2);
    max-width: calc(2063px + (100% - 2063px) / 2);
  }

  .content {
    max-width: 2004px;
    box-sizing: border-box;
  }
}

@keyframes appearance {
  0% {
    transform: translateY(150px);
    opacity: 0;
  }
  100% {
    transform: 0;
    opacity: 1;
  }
}
