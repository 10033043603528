.bottom {
  padding: 32px;
  max-width: 100%;
  display: grid;
  gap: 16px;
  align-content: space-between;
  @media (max-width: 991px) {
    padding: 32px 20px;
  }
}

.root {
  border-radius: 24px;
  background: #FAFAFA;
  height: 100%;
  display: grid;
  grid-template-rows: 280px auto;
  width: 100%;
  overflow: hidden;
  @media (max-width: 991px) {
    grid-template-rows: 200px auto;
    width: 100%;
    max-width: 100%;
  }
  cursor: pointer;
  transition: all .2s;
}

.root:hover {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.img {
  height: 280px;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  @media (max-width: 991px) {
    height: 200px;
  }
}

.title {
  font-size: 24px;
  line-height: 120%;
  color: #141414;
  margin-bottom: 16px;
  font-weight: 700;
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 21px;
  }
}

.text {
  font-size: 18px;
  line-height: 150%;
  color: #595959;
  @media (max-width: 991px) {
    font-size: 16px;
  }
}

.btn {
  align-self: end;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  color: #13AD37;
  width: min-content;
  border: none;
  text-align: left;
  background: none;
  display: flex;
  align-items: center;
  gap: 15px;
}
