.root {
  margin: 80px 0;
  position: relative;
}

.content {
  border-radius: 24px 0 0 24px;
  padding: 60px 40px 25px 44px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: linear-gradient(280.42deg, #13ad37 16.95%, #37ad13 81.65%);
  color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.bg {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 0;
}

.bg::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('/images/feedback/bg-image.png') no-repeat;
  background-position: top -200px right 120px;
  opacity: 0;
  z-index: 1;
  top: 0;
  right: 0;
  transition: background-position 1s ease-in-out 0.5s,
    opacity 0.5s ease-in-out 0.5s;
}

.bg::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('/images/feedback/bg-figure.png') no-repeat;
  background-position: top -600px right 0;
  background-size: contain;
  opacity: 0;
  top: 0;
  right: 0;
  transition: background-position 1s ease-in-out 0s,
    opacity 0.5s ease-in-out 0.5s;
}

.bg_active::after {
  background-position: top -120px right 120px;
  opacity: 1;
}

.bg_active::before {
  background-position: top left;
  opacity: 1;
}

.block {
  display: flex;
  margin-right: 0;
  margin-left: calc((100% - 1268px) / 2);
  max-width: calc(1268px + (100% - 1268px) / 2);
}

.title {
  font-size: 48px;
  line-height: 52px;
  max-width: 437px;
  margin: 0 0 24px;
  opacity: 0;
  font-weight: 700;
  z-index: 1;
}

.title_active {
  animation: appearance 0.7s ease-in-out 0.5s forwards;
}

.description {
  font-size: 18px;
  line-height: 27px;
  max-width: 422px;
  opacity: 0;
  margin: 0 0 47px;
  z-index: 1;
}

.description_active {
  animation: appearance 0.9s ease-in-out 0s forwards;
}

.form {
  z-index: 1;
}

.form_upper {
  max-height: 53px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 177px;
  gap: 49px;
  margin-bottom: 16px;
  height: 100%;
  transition: max-height 0.3s ease-in-out;
}

.form_error {
  max-height: 85px;
}

.form_lower {
  display: flex;
  justify-content: flex-end;
}

.link {
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}

.checkbox_active {
  animation: appearanceBottom 0.9s ease-in-out 0.5s forwards;
}

.submit {
  color: #14b339;
  font-size: 14px;
  height: 48px;
  opacity: 0;
}

.submit_active {
  animation: elementOpacity 0.4s ease-in-out 1.5s forwards;
}

.submit::before {
  background: #ffffff;
}

.submit::after {
  background: #ffffff;
}

.label_active_1 {
  animation: appearance 1s ease-in-out 0.1s forwards;
}

.label_active_2 {
  animation: appearance 1.2s ease-in-out 0.3s forwards;
}

.label_active_3 {
  animation: appearance 1.4s ease-in-out 0.3s forwards;
}

@media (max-width: 1365px) {
  .block {
    margin-left: calc((100% - 1183px) / 2);
    max-width: calc(1183px + (100% - 1183px) / 2);
  }

  .title {
    font-size: 42px;
    line-height: 46px;
  }
}

@media (max-width: 1279px) {
  .block {
    margin-left: calc((100% - 911px) / 2);
    max-width: calc(911px + (100% - 911px) / 2);
  }

  .form_upper {
    gap: 30px;
  }

  .content {
    background-position: right top;
  }
}

@media (max-width: 990px) {
  .block {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  .bg::after {
    background-position: bottom -200px right 50%;
    background-size: 75%;
  }

  .bg_active::after {
    background-position: bottom 30px right 50%;
  }

  .form_upper {
    grid-template-columns: repeat(1, 1fr);
    gap: 49px;
    max-height: unset;
  }

  .submit {
    margin: 0;
    height: 48px;
    font-size: 16px;
  }

  .content {
    border-radius: 24px;
    box-sizing: border-box;
    height: unset;
  }

  .description {
    margin-bottom: 95px;
  }
}

@media (max-width: 767px) {
  .root {
    margin: 80px 0 0;
  }

  .block {
    padding: 0;
  }

  .content {
    width: 100%;
    padding: 40px 16px 317px;
    background-position: bottom center;
    border-radius: 24px 24px 0 0;
  }

  .title {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 24px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  .bg::before {
    background-size: auto;
  }
}

@media (max-width: 500px) {
  .block {
    align-items: flex-start;
  }
  .title_small {
    margin-left: 16px;
  }
}

@media (min-width: 1600px) {
  .block {
    margin-left: calc((100% - 1512px) / 2);
    max-width: calc(1512px + (100% - 1512px) / 2);
  }

  .title {
    font-size: 56px;
    line-height: 62px;
  }

  .root {
    margin: 96px 0;
  }
}

@media (min-width: 1920px) {
  .block {
    margin-left: calc((100% - 1814px) / 2);
    max-width: calc(1814px + (100% - 1814px) / 2);
  }
}

@media (min-width: 2560px) {
  .block {
    margin-left: calc((100% - 2063px) / 2);
    max-width: calc(2063px + (100% - 2063px) / 2);
  }

  .content {
    padding: 60px 77px 25px 115px;
  }
}

@keyframes appearance {
  0% {
    transform: translateY(-150px);
    opacity: 0;
  }
  100% {
    transform: 0;
    opacity: 1;
  }
}

@keyframes appearanceBottom {
  0% {
    transform: translateY(150px);
    opacity: 0;
  }
  100% {
    transform: 0;
    opacity: 1;
  }
}

@keyframes elementOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
