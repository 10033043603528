.root {
  background: linear-gradient(280.42deg, #13ad37 16.95%, #37ad13 81.65%);
  padding: 47px 40px 83px;
  color: #ffffff;
  border-radius: 0 0 24px 24px;
  position: relative;
}
.officeBlock {
  margin-bottom: 15px;
}
.column__list {
  display: flex;
  flex-direction: column;
}
.bg {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 0;
}

.bg::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('/images/banner/bg-logo.png') no-repeat;
  background-position: top -250px right 0;
  background-size: contain;
  opacity: 0;
  top: 0;
  right: 0;
  transition: background-position 0.4s ease-in-out 0.4s,
    opacity 0.5s ease-in-out 0.6s;
}

.bg::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('/images/banner/bg-image.png') no-repeat;
  background-position: top -400px right 0;
  background-size: cover;
  opacity: 0;
  z-index: 1;
  top: 0;
  right: 0;
  transition: background-position 0.7s ease-in-out 0.3s,
    opacity 0.5s ease-in-out 0.5s;
}

.bg_active::after {
  background-position: top right;
  opacity: 1;
}

.bg_active::before {
  background-position: top right;
  opacity: 1;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
  z-index: 2;
}

.title {
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
  opacity: 0;
  margin: 0 0 32px;
  max-width: 725px;
}

.title_active {
  animation: appearance 0.3s ease-in-out 0.15s forwards;
}

.description {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  margin: 0 0 29px;
  max-width: 704px;
  opacity: 0;
}

.description_active {
  animation: appearance 0.3s ease-in-out 0s forwards;
}

.container {
  display: flex;
  align-items: center;
  margin-bottom: 31px;
  padding-right: 81px;
  justify-content: flex-end;
}

.line {
  width: calc(50% + 301px);
  height: 2px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  max-width: 0;
}

.line_active {
  animation: lineMove 0.5s ease-in 0.5s forwards;
}

.submit {
  color: #14b339;
  font-size: 16px;
  padding: 15px 45px;
  white-space: nowrap;
  justify-self: flex-end;
  opacity: 0;
}

.submit_active {
  animation: elementOpacity 0.4s ease-in-out 0.8s forwards;
}

.submit:before {
  background: #fff;
}

.submit::after {
  background-color: #ffffff;
}

.columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 48px;
}

.column {
  display: flex;
  flex-direction: column;
  z-index: 3;
}

.column__title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 15px;
  line-height: 26px;
}

.column__description {
  font-size: 16px;
  line-height: 21px;
}

.column_active:first-child {
  opacity: 0;
  animation: appearance 0.4s ease-in-out 0.25s forwards;
}

.column_active:nth-child(2) {
  opacity: 0;
  animation: appearance 0.4s ease-in-out 0.3s forwards;
}

.column_active:nth-child(3) {
  opacity: 0;
  animation: appearance 0.4s ease-in-out 0.35s forwards;
}

.column_active:nth-child(4) {
  opacity: 0;
  animation: appearance 0.4s ease-in-out 0.4s forwards;
}

.column:first-child .column__description {
  max-width: 135px;
}

.column:nth-child(2) .column__description {
  max-width: 202px;
}

.column:nth-child(3) .column__description {
  max-width: 150px;
}

.column:nth-child(4) .column__description {
  max-width: 150px;
}

@media (min-width: 1600px) {
  .wrapper {
    max-width: 1512px;
  }

  .root {
    padding: 47px 40px 95px;
  }

  .title {
    font-size: 64px;
    line-height: 70px;
    margin: 0 0 32px;
    max-width: 965px;
  }

  .description {
    font-size: 22px;
    line-height: 33px;
    margin: 0 0 55px;
    max-width: 704px;
  }

  .line {
    width: calc(50% + 422px);
  }

  .column__title {
    font-size: 28px;
    line-height: 31px;
    margin-bottom: 15px;
  }

  .column:nth-child(2) .column__description {
    max-width: 342px;
  }
}

@media (min-width: 1920px) {
  .wrapper {
    max-width: 1814px;
  }

  .root {
    padding: 103px 53px 127px;
  }

  .title {
    margin-bottom: 48px;
  }

  .description {
    margin-bottom: 95px;
  }

  .container {
    margin-bottom: 85px;
    padding-right: 47px;
  }

  .line {
    width: calc(50% + 607px);
  }

  .columns {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 2560px) {
  .wrapper {
    max-width: 2054px;
  }

  .line {
    width: calc(50% + 722px);
  }
  .column__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

@media (max-width: 1365px) {
  .root {
    padding: 47px 40px 67px;
  }

  .title {
    font-size: 42px;
    line-height: 46px;
    margin: 0 0 24px;
    max-width: 725px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 35px;
    max-width: 618px;
  }

  .container {
    margin-bottom: 22px;
  }

  .line {
    width: calc(50% + 258px);
  }
}

@media (max-width: 1279px) {
  .root {
    background-position: top right;
    padding: 37px 30px 40px;
  }

  .title {
    font-size: 38px;
    line-height: 42px;
    margin: 0 0 22px;
  }

  .description {
    font-size: 15px;
    line-height: 22px;
    margin: 0px 0 28px;
    max-width: 579px;
  }

  .container {
    padding: 0;
  }

  .line {
    width: calc(50% + 203px);
  }

  .column__title {
    font-size: 21px;
    margin-bottom: 12px;
    line-height: 24px;
  }

  .columns {
    grid-template-columns: 1fr 1fr 1fr 0.8fr 0.8fr;
    gap: 10px;
  }

  .column__description {
    font-size: 15px;
  }
  .column__list {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 990px) {
  .line {
    width: calc(100% - 282px);
  }

  .bg_active::before {
    background-size: cover;
  }
}

@media (max-width: 767px) {
  .root {
    padding: 40px 16px;
  }

  .bg_active::before {
    background: url('/images/banner/bg-image_mobile.png') no-repeat;
    background-position: right top;
  }

  .bg_active::after {
    content: none;
  }

  .title {
    font-size: 32px;
    line-height: 35px;
    margin: 0 0 16px;
  }

  .description {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 32px;
  }

  .columns {
    grid-template-columns: repeat(1, 1fr);
    gap: 48px;
  }

  .column__title {
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 26px;
  }

  .column__description {
    font-size: 16px;
  }

  .column:first-child .column__description {
    max-width: 135px;
  }

  .column:nth-child(2) .column__description {
    max-width: 325px;
  }

  .column:nth-child(3) .column__description {
    max-width: 150px;
  }

  .column:nth-child(4) .column__description {
    max-width: 150px;
  }
}

@keyframes appearance {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: 0;
    opacity: 1;
  }
}

@keyframes lineMove {
  0% {
    max-width: 0;
  }

  100% {
    max-width: 100%;
  }
}

@keyframes elementOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
