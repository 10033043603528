.root {
  display: grid;
  grid-template-columns: repeat(4, 287px);
  gap: 20px;
}

.wrapper {
  display: flex;
  margin-top: 80px;
}

.large {
  grid-column: span 2;
}

.grid {
  grid-template-columns: repeat(4, 1fr) !important;
}

@media (min-width: 1600px) {
  .root {
    grid-template-columns: repeat(4, 348px);
  }
  .wrapper {
    margin-top: 96px;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .root {
    grid-template-columns: repeat(4, 408px);
  }
  .wrapper {
    max-width: 100%;
  }
}

@media (min-width: 2560px) {
  .root {
    grid-template-columns: repeat(4, 485px);
  }

  .wrapper {
    max-width: 2063px;
  }
}

@media (max-width: 1365px) and (min-width: 1280px) {
  .root {
    grid-template-columns: repeat(4, 265px);
  }
}

@media (max-width: 1279px) and (min-width: 991px) {
  .grid {
    display: flex;
    flex-direction: column;
  }
  .root {
    grid-template-columns: repeat(4, calc(100% / 4 - 15px));
  }
  .main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr!important;
    .card {
      grid-column: span 1 !important;
    }
  }
}

@media (max-width: 990px) {
  .wrapper {
    flex-direction: column;
  }

  .root {
    grid-template-columns: repeat(2, calc(100% / 2 - 10px));
  }

  .grid {
    display: flex;
    flex-direction: column;
  }
  .main {
    display: flex !important;
    flex-direction: row !important;
    overflow-x: auto;
    overflow-y: hidden;
    .card {
      min-width: 285px;
    }
  }
}

@media (max-width: 767px) {
  .wrapper {
    margin-top: 60px;
  }
  .main {
    display: flex !important;
    flex-direction: row !important;
    overflow-x: auto;
    overflow-y: hidden;
    .card {
      min-width: 285px;
    }
  }
}

@media (max-width: 600px) {

  .root {
    grid-template-columns: repeat(6, 285px);
    overflow-x: auto;
    overflow-y: hidden;
    gap: 16px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 16px;
  }
  .root::-webkit-scrollbar {
    display: none;
  }
  .wrapper {
    align-items: flex-start;
  }
  .main {
    display: flex !important;
    flex-direction: row !important;
    overflow-x: auto;
    overflow-y: hidden;
    .card {
      min-width: 285px;
    }
  }
}
