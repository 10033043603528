.root {
  margin: 80px 0;
  overflow: hidden;
}

.wrapper {
  display: flex;
}

.items {
  display: grid;
  grid-template-columns: repeat(4, 287px);
  gap: 20px;
}

.card {
  background: linear-gradient(273.86deg, #13ad37 18.98%, #37ad13 88.4%);
  border-radius: 24px;
  overflow: hidden;
  min-height: 429px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 20px 40px;
  box-sizing: border-box;
  transform: translateY(400px);
  opacity: 0;
  transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
}

.card_active_1,
.card_active_2,
.card_active_3,
.card_active_4 {
  transform: translateY(0);
  opacity: 1;
}

.card_active_1 {
  transition-delay: 0s;
}

.card_active_2 {
  transition-delay: 0.03s;
}

.card_active_3 {
  transition-delay: 0.05s;
}

.card_active_4 {
  transition-delay: 0.07s;
}

.title {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  margin: auto 0 16px;
  position: relative;
  z-index: 2;
}

.title_big {
  font-weight: 900;
  font-size: 128px;
  line-height: 154px;
  text-transform: uppercase;
  color: #14b339;
  position: absolute;
  left: -30px;
  top: 0;
  writing-mode: vertical-lr;
  pointer-events: none;
}

.text {
  position: relative;
  z-index: 2;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
}

.icon__wrapper {
  width: 156px;
  height: 156px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  position: absolute;
  top: -18px;
  right: -18px;
  border-radius: 50%;
}

@media (min-width: 1600px) {
  .root {
    margin: 96px 0;
  }
  .items {
    grid-template-columns: repeat(4, 345px);
    gap: 24px;
  }

  .title {
    font-size: 24px;
    line-height: 29px;
  }

  .text {
    font-size: 18px;
    line-height: 27px;
  }
}

@media (min-width: 1920px) {
  .wrapper {
    max-width: 1752px;
  }
  .items {
    grid-template-columns: repeat(4, 405px);
  }

  .title {
    font-size: 24px;
    line-height: 29px;
  }

  .text {
    font-size: 18px;
    line-height: 27px;
  }
}

@media (min-width: 2560px) {
  .items {
    grid-template-columns: repeat(4, 483px);
  }

  .wrapper {
    max-width: 2063px;
  }
}

@media (max-width: 1365px) {
  .items {
    grid-template-columns: repeat(4, 265px);
  }
  .title {
    margin-top: 172px;
  }
}

@media (max-width: 1279px) {
  .items {
    grid-template-columns: repeat(2, calc(100% / 2 - 10px));
    grid-template-rows: 389px;
  }
  .card {
    min-height: auto;
  }
}

@media (max-width: 990px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
  }
  .items {
    grid-template-rows: auto;
  }
}

@media (max-width: 767px) {
  .root {
    margin: 60px 0;
  }
}

@media (max-width: 670px) {
  .items {
    grid-template-columns: repeat(4, 285px);
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 16px;
  }
  .items::-webkit-scrollbar {
    display: none;
  }
}
